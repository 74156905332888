import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { Checkbox, FormControlLabel } from '@mui/material';
import MainLayout from '../AppSkeleton/MainLayout/MainLayout';
import { baseSideMenuItems } from '../AppSkeleton/SideMenu/side-menu-items';
import LoadingIcon from '../xShared/LoadingIcon';
import RelativesSearch from './RelativesSearch';
import { MarkerSet } from '../../apiTypes/geneticData';
import Tabs from '../xShared/Tabs';

interface MarkerSetQueryData {
  listAvailableMarkerSets: MarkerSet[];
}

const comparisonSetsQuery = gql`
  query {
    listAvailableMarkerSets {
      name
      markerCount
    }
  }
`;

function RelativesSearchPage() {
  const [selectedMarkerSet, setSelectedMarkerSet] = useState<string>();
  const [limitToOwnSurname, setLimitToOwnSurname] = useState(false);
  const [searchSubtree, setSearchSubtree] = useQueryParam('subtree', BooleanParam);

  const { data } = useQuery<MarkerSetQueryData>(comparisonSetsQuery, {
    onCompleted: (d) => {
      setSelectedMarkerSet(d.listAvailableMarkerSets[0].name);
    },
  });
  const { t } = useTranslation(['relatives', 'skeleton']);

  const markerSets = data?.listAvailableMarkerSets
    .map((ms) => ({
      key: ms.name,
      data: ms,
    }))
    .sort((a, b) => a.data.markerCount - b.data.markerCount);

  return (
    <MainLayout sideMenuItems={baseSideMenuItems}>
      {selectedMarkerSet ? (
        <Container className="mt-5 mb-4">
          <h1 className="header mb-5">{t('skeleton:baseSideMenu.relativesSearch')}</h1>

          <Tabs
            tabs={markerSets}
            renderFunction={(d) => <div>{t('markerCount', { markerCount: d.data.markerCount })}</div>}
            onFocusedChanged={(d) => setSelectedMarkerSet(d.name)}
            selectedTabIndex={markerSets.findIndex((ms) => ms.data.name === selectedMarkerSet)}
          >
            <div className="container pb-3 pt-3">
              <div className="relative-search-filter-box mb-4 ms-2">
                <FormControlLabel
                  control={
                    <Checkbox checked={limitToOwnSurname} onChange={(e) => setLimitToOwnSurname(e.target.checked)} />
                  }
                  label={t('ownSurnameOnly')}
                />

                <FormControlLabel
                  control={<Checkbox checked={searchSubtree} onChange={(e) => setSearchSubtree(e.target.checked)} />}
                  label={t('searchSubtree')}
                />
              </div>

              <RelativesSearch
                selectedMarkerSet={selectedMarkerSet}
                limitToOwnSurname={limitToOwnSurname}
                searchSubtree={searchSubtree}
              />
            </div>
          </Tabs>
        </Container>
      ) : (
        <LoadingIcon />
      )}
    </MainLayout>
  );
}

export default RelativesSearchPage;

import dayjs from 'dayjs';
import ApiClient from '../../utils/api-client';
import { FilterProps, LogsPaginationParams, LogsResponse, RequestStatus } from './models';
import { endpointsQuery, RawEndpoints, logsQuery, RawLogs } from './queries';

export async function getEndpointOptions(): Promise<string[]> {
  const rawData = await ApiClient.current.query<RawEndpoints>({
    query: endpointsQuery,
  });
  return rawData.data.loggedEndpointNames;
}

export async function getLogs(filter: FilterProps, paginationOptions: LogsPaginationParams): Promise<LogsResponse> {
  const filterForApi = trimStrings({
    ...filter,
    from: filter.from?.toISOString(),
    to: filter.to?.toISOString(),
    requestStatus:
      filter.requestStatus !== null && filter.requestStatus !== undefined
        ? filter.requestStatus === RequestStatus.OK
        : null,
    endpointNames: filter.endpointName ? [filter.endpointName] : null,
  });

  const response = await ApiClient.current.query<RawLogs>({
    query: logsQuery,
    variables: {
      takeLogs: paginationOptions.pageSize,
      skipLogs: paginationOptions.pageIndex * paginationOptions.pageSize,
      ...filterForApi,
    },
  });
  const rawData = response.data.anyUserLogs;

  const finalData: LogsResponse = {
    metadata: { totalCount: rawData.totalCount },
    logs: rawData.items.map((rawLog) => ({
      requestId: rawLog.requestId,
      requestStatus: rawLog.requestSuccessful ? RequestStatus.OK : RequestStatus.FAIL,
      date: dayjs(rawLog.date),
      affectedUser: {
        id: rawLog.aboutUser?.profile.id ? +rawLog.aboutUser.profile.id : null,
        email: rawLog.aboutUser?.profile.email,
        name: getName(rawLog.aboutUser?.profile),
      },
      performer: {
        id: +rawLog.performedByUser.profile.id,
        email: rawLog.performedByUser.profile.email,
        name: getName(rawLog.performedByUser.profile),
      },
      endpoint: rawLog.endpoint,
      raw: rawLog.raw,
    })),
  };

  return finalData;
}

function getName(profile: { givenName?: string; familyName?: string }) {
  if (!profile) {
    return null;
  }
  return !!profile.givenName && !!profile.familyName ? `${profile.givenName} ${profile.familyName}` : null;
}

function trimStrings<T>(object: T): T {
  const newObject = { ...object };

  Object.keys(newObject).forEach((key) => {
    if (typeof newObject[key] === 'string') {
      newObject[key] = (newObject[key] as string).trim();
    }
  });

  return newObject;
}

import './Errors.css';
import { Component, useEffect } from 'react';
import { Alert, AlertColor, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
  heading?: string;
  severity?: AlertColor;
  message: string;
}

function ErrorMessage({ onClose, heading, message, severity = 'error' }: Props) {
  const { t } = useTranslation('skeleton');
  const timeToLive = 5000;

  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, timeToLive);
  }, [onClose, timeToLive]);

  return (
    <Alert severity={severity} onClose={onClose} className="message-popup">
      {heading && <AlertTitle>{heading}</AlertTitle>}
      {message.trim() || t('unknownErrorOccurred')}
      <div className="message-time-to-live-progress" style={{ animationDuration: `${timeToLive}ms` }} />
    </Alert>
  );
}

// this is a bit of a hack to allow us to update the component state from outside the React tree
// eslint-disable-next-line react/prefer-stateless-function
class Errors extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
  }

  removeMessage(key: string) {
    return () =>
      this.setState((state) => {
        const newErrors = [...state.errors];

        const index = newErrors.findIndex((error) => error.key === key);
        if (index !== -1) {
          newErrors.splice(index, 1);
        }

        return { ...state, errors: newErrors };
      });
  }

  // the method is used, just not in this file (see api-client.ts)
  // eslint-disable-next-line react/no-unused-class-component-methods
  addMessage(text: string, severity: AlertColor = 'error', translate = true) {
    this.setState((state) => ({
      ...state,
      errors: [...state.errors, { message: text, severity, translate, key: randomString() }],
    }));
  }

  render() {
    const { t } = this.props;
    return (
      <div className="message-popup-container">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {this.state.errors.map(({ message, severity, translate, key }) => (
          <ErrorMessage
            key={key}
            severity={severity}
            onClose={this.removeMessage(key)}
            message={translate ? t(message) : message}
          />
        ))}
      </div>
    );
  }
}

function randomString() {
  return Math.random().toString(36).substring(7);
}

export default Errors;

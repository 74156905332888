import './LogsTable.css';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { LogRecord, RequestStatus, TablePaginationActionsProps, PagingInfo, FilterProps } from './models';
import { getLogs } from './data-providers';

interface LogTableComponentProps {
  filter: FilterProps;
}

const TIMESTAMP_FORMAT = 'DD.MM.YYYY HH:mm:ss';
const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export default function LogsTable({ filter }: LogTableComponentProps) {
  const [displayedLogs, setDisplayedLogs] = useState<LogRecord[]>(null);
  const [logForDetail, setLogForDetail] = useState<LogRecord>(null);
  const [totalLogCount, setTotalLogCount] = useState<number>(0);
  const [loadingNewPage, setLoadingNewPage] = useState<boolean>(false);
  const [pagingInfo, setPagingInfo] = useState<PagingInfo>({
    pageSize: PAGE_SIZE_OPTIONS[0],
    currentPage: 0,
  });
  const { t } = useTranslation('logging');

  useEffect(() => {
    setDisplayedLogs(null);
    setPagingInfo((prev) => ({
      pageSize: prev.pageSize,
      currentPage: 0,
    }));
  }, [filter]);

  useEffect(() => {
    getLogs(filter, {
      pageIndex: pagingInfo.currentPage,
      pageSize: pagingInfo.pageSize,
    })
      .then((data) => {
        if (!data) {
          setDisplayedLogs([]);
          return;
        }

        const { metadata, logs } = data;
        setDisplayedLogs(logs);
        setTotalLogCount(metadata.totalCount);
      })
      .catch(() => {
        setDisplayedLogs([]);
      })
      .finally(() => {
        setLoadingNewPage(false);
      });
  }, [filter, pagingInfo]);

  function handlePageChange(page: number) {
    setPagingInfo((prev) => ({
      ...prev,
      currentPage: page,
    }));
    setLoadingNewPage(true);
  }

  function handlePageSizeChange(rowsPerPage: number) {
    setPagingInfo((prev) => ({
      ...prev,
      pageSize: rowsPerPage,
    }));
    setLoadingNewPage(true);
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="log-table-title-row">
              <TableCell> {t('requestId')}</TableCell>
              <TableCell align="right">{t('performer')}</TableCell>
              <TableCell align="right">{t('affectedUser')}</TableCell>
              <TableCell align="right">{t('endpointName')}</TableCell>
              <TableCell align="right">{t('timestamp')}</TableCell>
              <TableCell align="right">{t('requestStatus')}</TableCell>
              <TableCell align="right">{t('detail')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!displayedLogs && (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="logs-spinner-container">
                    <Spinner />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {displayedLogs &&
              displayedLogs.map((row) => (
                <TableRow
                  key={row.requestId}
                  className={[
                    'logs-table-row',
                    loadingNewPage ? 'loading-new-page-for-logs-table' : '',
                    row.requestStatus === RequestStatus.FAIL ? 'log-row-failed-request' : '',
                  ].join(' ')}
                >
                  <TableCell component="th" scope="row">
                    {row.requestId}
                  </TableCell>
                  <TableCell align="right">
                    {row.performer.name && (
                      <div className="logs-user-name">
                        <FontAwesomeIcon icon={faUser} className="logs-icon-color me-1" />
                        {row.performer.name}
                      </div>
                    )}
                    <div className="logs-user-email">
                      <FontAwesomeIcon icon={faEnvelope} className="logs-icon-color me-1" />
                      {row.performer.email}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {row.affectedUser.name && (
                      <div className="logs-user-name">
                        <FontAwesomeIcon icon={faUser} className="logs-icon-color me-1" />
                        {row.affectedUser.name}
                      </div>
                    )}
                    <div className="logs-user-email">
                      {row.affectedUser.email && <FontAwesomeIcon icon={faEnvelope} className="logs-icon-color me-1" />}
                      {row.affectedUser.email}
                    </div>
                  </TableCell>
                  <TableCell align="right">{row.endpoint}</TableCell>
                  <TableCell align="right">{row.date.format(TIMESTAMP_FORMAT)}</TableCell>
                  <TableCell align="right">{row.requestStatus === RequestStatus.OK ? t('OK') : t('FAIL')}</TableCell>
                  <TableCell align="right">
                    <FontAwesomeIcon
                      icon={faEye}
                      className="show-log-detail-icon logs-icon-color"
                      onClick={() => setLogForDetail(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            {displayedLogs && (
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                  colSpan={6}
                  count={totalLogCount}
                  rowsPerPage={pagingInfo.pageSize}
                  page={pagingInfo.currentPage}
                  labelRowsPerPage={t('rowsPerPage')}
                  onPageChange={(_, page) => handlePageChange(page)}
                  onRowsPerPageChange={(e) => handlePageSizeChange(e.target.value as unknown as number)}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <Modal show={!!logForDetail}>
        <Modal.Header>
          <h3 className="header">{logForDetail?.requestId}</h3>
        </Modal.Header>
        <Modal.Body>
          <h5 className="header mt-1">{t('performer')}</h5>
          <p>{logForDetail?.performer.email}</p>

          <h5 className="header mt-4">{t('affectedUser')}</h5>
          <p>{logForDetail?.affectedUser.email}</p>

          <h5 className="header mt-4">{t('endpointName')}</h5>
          <p>{logForDetail?.endpoint}</p>

          <h5 className="header mt-4">{t('timestamp')}</h5>
          <p>{logForDetail?.date.format(TIMESTAMP_FORMAT)}</p>

          <h5 className="header mt-4">{t('rawLog')}</h5>
          <pre>{getFormattedRawLog(logForDetail?.raw)}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button color="error" variant="outlined" onClick={() => setLogForDetail(null)}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

function getFormattedRawLog(raw: string) {
  if (!raw) return '';

  try {
    const jsObject = JSON.parse(raw);
    const prettyString = JSON.stringify(jsObject, null, 3);
    return prettyString;
  } catch {
    return raw;
  }
}

import { gql } from '@apollo/client';

export interface RawEndpoints {
  loggedEndpointNames: string[];
}

export const endpointsQuery = gql`
  query Endpoints {
    loggedEndpointNames
  }
`;

export interface RawLogs {
  anyUserLogs: {
    totalCount: number;
    items: {
      requestId: string;
      performedByUser: {
        id: number;
        profile: {
          id: number;
          givenName?: string;
          familyName?: string;
          email: string;
        };
      };
      aboutUser: {
        id: number;
        profile: {
          id: number;
          givenName?: string;
          familyName?: string;
          email: string;
        };
      };
      endpoint: string;
      date: string;
      requestSuccessful: boolean;
      raw: string;
    }[];
  };
}

export const logsQuery = gql`
  query GetLogs(
    $takeLogs: Int
    $skipLogs: Int
    $requestId: String
    $performerEmail: String
    $affectedUserEmail: String
    $endpointName: [String!]
    $from: DateTime
    $to: DateTime
    $requestStatus: Boolean
  ) {
    anyUserLogs(
      logFilter: {
        aboutUserEmail: $affectedUserEmail
        performerEmail: $performerEmail
        from: $from
        to: $to
        requestId: $requestId
        endpointNames: $endpointName
        requestSuccessful: $requestStatus
      }
      order: [{ date: DESC }]
      skip: $skipLogs
      take: $takeLogs
      includeRawLogs: true
    ) {
      totalCount
      items {
        requestId
        performedByUser {
          ... on Admin {
            __typename
            id
            profile {
              email
            }
          }
          ... on User {
            __typename
            id
            profile {
              email
              givenName
              familyName
            }
          }
        }
        aboutUser {
          ... on Admin {
            __typename
            id
            profile {
              email
            }
          }
          ... on User {
            __typename
            id
            profile {
              email
              givenName
              familyName
            }
          }
        }
        endpoint
        date
        requestSuccessful
        raw
      }
    }
  }
`;

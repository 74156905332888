import { i18n } from 'i18next';

export type OperationFilterInput<T> = { in: T[] } | { eq: T } | { startsWith: T } | { nin: [] };
export const EmptyFilterInput: OperationFilterInput<any> = { nin: [] };

export interface OperationResult {
  operationSuccessful: boolean;
}

export enum Language {
  Czech = 'CZECH',
  English = 'ENGLISH',
}

export function getCurrentLanguage(int: i18n): Language {
  return int.language === 'cs' ? Language.Czech : Language.English;
}

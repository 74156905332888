import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationProps } from './types';
import { ButtonRow } from './Buttons';
import { stateUpdater, validateAndSubmit } from './utils';

function RegistrationConsentForm({ setData, onNext, onBack }: RegistrationProps) {
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation('registration');

  return (
    <Form className="mb-5" noValidate validated={validated} onSubmit={validateAndSubmit(onNext, setValidated)}>
      <h3>{t('consent')}</h3>
      <div className="consent-text-content">
        <RegistrationText />
      </div>
      <Form.Group>
        <Form.Check
          onChange={stateUpdater('consent', setData, 'checked')}
          required
          type="checkbox"
          name="consent"
          label={t('description.consent')}
        />
      </Form.Group>
      <ButtonRow onBackClick={onBack} />
    </Form>
  );
}
export default RegistrationConsentForm;

function RegistrationText() {
  return (
    <div className="mt-4">
      <p>
        <b>SOUHLAS SE ZPRACOVÁNÍM OSOBNÍCH ÚDAJŮ</b>&nbsp; pro výzkumnou databázi DNA profilů Výzkumná databáze DNA
        profilů (dále jen Projekt) je realizována na Kriminalistickém ústavu Praha ve spolupráci s dalšími vědeckými
        pracovišti – MFF UK, VŠCHT, 1. LF UK a slouží pro výzkumné projekty v oblasti populační genetiky, genetické
        genealogie a lékařské genetiky. Seznam zapojených grantových úkolů je uvedený na webových stránkách Projektu.
        Účastníci se do Projektu zapojují prostřednictvím výzkumného úkolu Genetika a příjmení, jehož popis naleznou ve
        svém účtu na webové stránce projektu.
      </p>
      <p>
        Analýza DNA je hrazena z grantového úkolu, pro účastníky je tedy zdarma. Za účast ve výzkumu není poskytována
        finanční odměna ani kompenzace. Účastník získá základní informace o svém haplotypu/ haploskupině.
      </p>
      <p>
        Osobní údaje účastníků Projektu jsou zabezpečeny proti zneužití v souladu s platnou legislativou a pravidly
        informační a kybernetické bezpečnosti. Vzorek biologického materiálu je použit výhradně pro vědecké účely.
      </p>
      <p>
        Informovaný souhlas se zpracováním svých osobních údajů uděluje účastník (Subjekt údajů) Kriminalistickému
        ústavu (dále jen „KÚ“´), se sídlem Strojnická 935/27, 170 89 Praha 7, IČO: 00007064 (Správce údajů), v souladu s
        článkem 6 nařízení Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických osob v souvislosti se
        zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o
        ochraně osobních údajů – GDPR), a to za níže uvedených podmínek:
      </p>
      <p>
        1. Osobní údaje nezbytné pro zapojení do Projektu, s jejichž zpracováním dáváte souhlas:
        <ul>
          <li>jméno a příjmení,</li>
          <li>datum narození,</li>
          <li>adresa,</li>
          <li>e-mail,</li>
          <li>telefonní číslo,</li>
          <li>vzorek biologického materiálu,</li>
          <li>výsledek analýzy vzorku.</li>
        </ul>
      </p>
      <p>Vaše osobní údaje budou v rámci archivace zpracovávány po dobu trvání Projektu.</p>
      <p>
        2. Konkrétní osobní údaje budou zpracovávány následujícím způsobem, se kterým vyjadřujete svůj souhlas:
        <ul>
          Pro udržování vzájemné poštovní, e-mailové a telefonické komunikace a pro zaslání testovací soupravy budou
          zpracovávány: jméno, příjmení, adresa, e-mail, telefon.
        </ul>
        <ul>
          Pro provedení analýzy vzorku biologického materiálu budou zpracovávány: jméno a příjmení, datum narození,
          vzorek biologického materiálu.
        </ul>
        <ul>
          Pro tvorbu statistik a grafů dostupných účastníkům vzájemně dle vyhodnocených shod: příjmení, e-mail, obec s
          rozšířenou působností dle uvedené adresy, výsledek analýzy vzorku biologického materiálu.
        </ul>
        <ul>
          Pro vklad výsledku analýzy vzorku biologického materiálu do databáze budou zpracovávány: jméno a příjmení,
          datum narození, adresa, e-mail, telefonní číslo a výsledek analýzy vzorku biologického materiálu.
        </ul>
        <ul>
          Pro potřeby archivace, po dobu trvání Projektu bude uchováván vzorek biologického materiálu v laboratoři KÚ,
          na adrese Kriminalistický ústav, Bartolomějská 310/10, 110 00 Praha 1.
        </ul>
      </p>
      <p>
        3. Zabezpečení osobních údajů &nbsp; Vaše osobní údaje budou zabezpečeny v souladu se zákonem č. 110/2019 Sb., o
        zpracování osobních údajů a s nařízením Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických osob v
        souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné
        nařízení o ochraně osobních údajů – GDPR) (dále jen „Nařízení“) formou pseudonymizace. Jedná se o skrytí
        identity, kdy jsou Vaše osobní údaje opatřeny kódem, pod kterým jsou zpracovávány. Spojení mezi kódem, který Vám
        byl přidělen, a Vašimi osobními údaji bude přístupné pouze pověřeným pracovníkům správce osobních údajů, jímž je
        KÚ (dále jen &quot;Správce&quot;), aby Vás v důvodných případech mohli kontaktovat nebo zlikvidovat Váš vzorek a
        informace, které jste poskytl, pokud byste svůj souhlas odvolal. Laboratoře KÚ jsou režimovým pracovištěm a
        přístup k osobním údajům je monitorován a chráněn. V souladu s Nařízením mohou mít k Vašim osobním údajům
        přístup také další osoby, vázané mlčenlivostí, jejichž seznam je uveden na webové stránce Projektu. Výsledky
        výzkumů budou rovněž publikovány pouze v anonymizované formě znemožňující identifikaci Vaší osoby a vždy v rámci
        souhrnu dalších dat získaných v Projektu.
      </p>
      <p>
        Dle Nařízení máte právo odvolat svůj souhlas a požádat Správce o vymazání osobních údajů a zlikvidování vzorku.
        Odvolání souhlasu a žádost o likvidaci vzorku může být provedeno písemnou, emailovou nebo telefonickou
        komunikací se Správcem. Pokud odvoláte svůj souhlas, mohou být Vaše údaje dále zpracovávány pouze v
        anonymizované podobě. Máte právo na přístup k Vašim osobním údajům, tj. získat od Správce informace o tom, zda a
        jakým způsobem Vaše osobní údaje zpracovává. Máte právo na ověřování Vašich osobních údajů, jejich opravu,
        doplnění a aktualizaci. Správce musí bez zbytečného odkladu požadované změny provést (učinit tak můžete také
        sami v nastavení svého účtu na webové stránce Projektu).
      </p>
      <p>
        Pokud zjistíte nebo se domníváte, že Správce provádí zpracování Vašich osobních údajů, které je v rozporu s
        ochranou Vašeho soukromého a osobního života nebo v rozporu se zákonem, zejména jsou-li osobní údaje nepřesné s
        ohledem na účel jejich zpracování, můžete:
        <ol>
          <li>požádat Správce o vysvětlení,</li>
          <li>
            požadovat, aby Správce odstranil takto vzniklý stav. Zejména se může jednat o blokování, provedení opravy,
            doplnění nebo likvidaci osobních údajů.
          </li>
        </ol>
      </p>
      <p>
        Správce odstraní neprodleně závadný stav. Nevyhoví-li Správce Vaší žádosti, máte právo obrátit se přímo na Úřad
        pro ochranu osobních údajů (ÚOOÚ), se sídlem Pplk. Sochora 727/27, Praha, 170 00. Přitom platí, že i když
        nevyužijete možnosti žádat napravení závadného stavu Správcem, je možné, abyste se obrátil se svým podnětem na
        ÚOOÚ přímo.
      </p>
      <p>
        Vedoucím Projektu je genetik KÚ Mgr. Vlastimil Stenzl, který je současně osobou oprávněnou jednat za Správce ve
        všech náležitostech Projektu včetně podání informací k zabezpečení osobních údajů. Prohlašuji, že jsem byl
        Správcem poučen o svých právech, že osobní údaje, které jsem uvedl, jsou přesné a pravdivé a jsou Správci
        poskytovány dobrovolně. Současně se zavazuji k informování Správce o změnách kontaktních údajů (případně o
        pověření jiné osoby ke komunikaci).
      </p>
    </div>
  );
}

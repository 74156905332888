import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import Map from '../Map/Map';
import './LandingPage.css';
import './LandingPage.phone.css';
import routes from '../../route-definitions';
import LanguageSwitch from '../AppSkeleton/LanguageSwitch/LanguageSwitch';
import LoginModal from './LoginModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import ftdnaLogo from '../../pictures/ftdna-logo.png';
import nevgenLogo from '../../pictures/nevgen-logo.png';
import mffLogo from '../../pictures/matfyz-logo.png';
import krimUstavLogo from '../../pictures/ku-logo.png';

const VISIBLE_STRIP_WIDTH = 10;

function LandingPage(): JSX.Element {
  const [infoClosed, setInfoClosed] = useState(false);
  const [hoverToggleTextBtn, setHoverToggleTextBtn] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isPwdResetOpen, setPwdResetOpen] = useState(false);
  const { t } = useTranslation('skeleton');
  const [textOffset, setTextOffset] = useState<number>(null);

  const textContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!textContainerRef.current) return;

    if (infoClosed) {
      const textWidth = textContainerRef.current.clientWidth;

      setTextOffset(-textWidth + VISIBLE_STRIP_WIDTH);
    } else {
      setTextOffset(null);
    }
  }, [textContainerRef, infoClosed]);

  useEffect(() => {
    window.addEventListener('resize', () => setInfoClosed(false));
  }, []);

  const landingTextAdditionalClasses =
    (infoClosed ? 'landing-text-closed ' : 'landing-text-opened ') +
    (hoverToggleTextBtn ? 'hover-on-toggle-landing-page-btn' : '');

  const handleForgotPassword = () => {
    setLoginOpen(false);
    setPwdResetOpen(true);
  };

  return (
    <div className="landing-page">
      <div
        ref={textContainerRef}
        style={{ marginLeft: textOffset }}
        className={`landing-text ${landingTextAdditionalClasses}`}
      >
        <div className="text-content">
          <div className="landing-lan-switch-container mt-4">
            <LanguageSwitch />
          </div>

          <div className="title">
            <h1>Výzkumná databáze DNA profilů</h1>
          </div>
          <p className="description mt-4">
            Osobní údaje jsou zabezpečeny v souladu se zák. č. 110/2019 Sb. a s nařízením Evropského parl. a Rady EU č.
            2016/679 (obecné nařízení o ochraně osobních údajů – GDPR).
            <br />
            <Link className="landing-page-link-color" to={routes.aboutPageRoute.path}>
              Přístup k projektu Genetika a Příjmení.
            </Link>
          </p>
        </div>

        <div className="footer-inside-landing-text">
          <LegalInfo />
        </div>
      </div>
      <div className="landing-page-buttons mt-2 ms-2 mb-2">
        <Link to={routes.registrationBaseRoute.path}>
          <Button color="success" variant="contained" type="button" className="px-3">
            {t('register')}
          </Button>
        </Link>
        <Button
          color="success"
          variant="outlined"
          type="button"
          className="ms-2 me-3"
          onClick={() => setLoginOpen(true)}
        >
          {t('login')}
        </Button>
      </div>
      <div className="landing-map">
        <button
          type="button"
          className="toggle-landing-page-btn"
          onClick={() => setInfoClosed(!infoClosed)}
          onMouseEnter={() => setHoverToggleTextBtn(true)}
          onMouseLeave={() => setHoverToggleTextBtn(false)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={`toggle-landing-page-icon ${infoClosed ? 'landing-text-closed' : ''}`}
          />
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={`toggle-landing-page-icon ${infoClosed ? 'landing-text-closed' : ''}`}
          />
        </button>

        <Map />
      </div>

      <div className="footer-landing-page">
        <LegalInfo />
      </div>

      <LoginModal show={isLoginOpen} onHide={() => setLoginOpen(false)} onForgotPassword={handleForgotPassword} />
      <ForgotPasswordModal show={isPwdResetOpen} onHide={() => setPwdResetOpen(false)} />
    </div>
  );
}

function LegalInfo() {
  return (
    <div className="legal-info">
      <Row className="logos-row">
        <Col xs={2} className="single-logo-container ku-logo">
          <a href="https://policie.gov.cz/kriminalisticky-ustav.aspx">
            <img src={krimUstavLogo} alt="FTDNA logo" className="landing-logo" />
          </a>
        </Col>
        <Col xs={4} className="single-logo-container mff-logo">
          <a href="https://www.mff.cuni.cz/">
            <img src={mffLogo} alt="NevGen logo" className="landing-logo" />
          </a>
        </Col>
        <Col xs={3} className="single-logo-container ftdna-logo">
          <a href="https://familytreedna.com">
            <img src={ftdnaLogo} alt="FTDNA logo" className="landing-logo" />
          </a>
        </Col>
        <Col xs={3} className="single-logo-container nevgen-logo">
          <a href="https://nevgen.org">
            <img src={nevgenLogo} alt="NevGen logo" className="landing-logo" />
          </a>
        </Col>
      </Row>
      <div className="legal-text-landing-page">
        <p>
          Výzkumná databáze DNA profilů byla realizovaná s podporou projektu bezpečnostního výzkumu MV č. VJ01010123.
        </p>
        <p>Copyright © 2025 Kriminalistický ústav PČR a Matematicko-fyzikální fakulta UK.</p>
      </div>
    </div>
  );
}

export default LandingPage;

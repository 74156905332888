import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <p className="mb-0">Výzkumná databáze DNA profilů</p>
        <p className="mb-0">Copyright © {new Date().getFullYear()} KÚ PČR a MFF UK.</p>
        <p className="mb-0">
          <a href="mailto:oznam.chybu@dnaportal.cz">oznam.chybu@dnaportal.cz</a>
        </p>
        <p>
          <a href="/about">Důležité informace.</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;

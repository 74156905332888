import { Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PublicUser } from '../../apiTypes/user';

interface Props {
  show: boolean;
  onHide: () => void;
  user: PublicUser;
}

function UserInfoModal({ onHide, show, user }: Props) {
  const { t } = useTranslation('relatives');

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <h3 className="header mt-2">{`${user.profile.givenName ?? ''} ${user.profile.familyName ?? ''}`}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <Row className="mt-4 mb-3">
            <div className="col-6">
              <h5 className="header">Email</h5>
              <p>{user.profile.email ?? ''}</p>
            </div>
            <div className="col-6">
              <h5 className="header">{t('haplogroup')}</h5>
              <p>{user.geneticData.haplogroup ?? ''}</p>
            </div>
          </Row>
          <div className="mb-4">
            <h5 className="header">{t('oldestKnownRelative')}</h5>
            <p>{`${user.ancestor.givenName ?? ''} ${user.ancestor.familyName ?? ''}`}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserInfoModal;
